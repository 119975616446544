import React, { useMemo, useState } from 'react';
import orelogo from 'assets/img/ore.png';
import Mine from 'components/Mine';
import { useUser, WidgetStep } from 'context/User';
import { Modal } from 'components/Modal';
import Login from 'components/Login';
import Bank from 'components/Bank';
import Leaderboard from 'components/Leaderboard';
import { cn } from 'utils/cn';
import {
  AvatarIcon,
  LeaderboardIcon,
  NftPickAxeIcon,
  PickAxeIcon,
} from 'components/Icons';
import MyPickaxes from 'components/MyPickaxes';

type Props = {
  isAppWidget?: boolean;
};

const Widget: React.FC<Props> = ({ isAppWidget }) => {
  const [openMineModal, setOpenMineModal] = useState(false);
  const { currentStep, setCurrentStep, userWallet } = useUser();

  const tabs = useMemo(
    () => [
      {
        icon: PickAxeIcon,
        name: 'Mine',
      },
      // {
      //   icon: NftPickAxeIcon,
      //   name: 'My Pickaxes',
      // },
      // {
      //   icon: LeaderboardIcon,
      //   name: 'Leaderboard',
      // },
      // {
      //   icon: AvatarIcon,
      //   name: 'Account',
      // },
    ],
    [],
  );

  const renderCurrentStep = useMemo(() => {
    switch (currentStep) {
      case 'Login':
        return <Login />;
      case 'Mine':
        return <Mine />;
      // case 'My Pickaxes':
      //   return <MyPickaxes />;
      // case 'Account':
      //   return <Bank />;
      // case 'Leaderboard':
      //  return <Leaderboard />;
    }
  }, [currentStep]);

  if (isAppWidget) {
    return (
      <div className="flex h-screen w-full flex-col justify-between">
        <div
          style={{
            height: 'calc(100% - 60px)',
          }}
          className="w-full overflow-y-auto p-[15px] lg:p-5">
          {renderCurrentStep}
        </div>

        <div className="grid h-[60px] w-full grid-cols-1 gap-x-5 bg-dark-700">
          {tabs.map((tab, key) => (
            <div className="flex items-center justify-center">
              <button
                onClick={() => setCurrentStep(tab.name as WidgetStep)}
                className={cn(
                  'text-[10px] h-[42px] w-[74px] rounded-md flex flex-col items-center justify-center gap-[1px] text-[#A1A7BB]',
                  currentStep === tab.name && ' text-white',
                )}
                key={key}>
                <tab.icon active={currentStep === tab.name} />
                {tab.name}
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <>
      <button
        onClick={() => setOpenMineModal(true)}
        className="ore-shadow fixed bottom-0 left-0 right-0 top-0 mx-auto my-auto size-[60px] overflow-hidden rounded-full bg-black">
        <img src={orelogo} alt="" />
      </button>

      {openMineModal && (
        <Modal
          onClose={() => setOpenMineModal(false)}
          className={{
            modal:
              'max-w-[360px] bottom-[104px] right-0 mx-auto left-0 lg:left-[500px] top-0',
          }}>
          <div className="permanent-marker-regular relative h-[510px] w-[360px] overflow-auto rounded-t-xl border border-dark-500 bg-dark-500 p-4">
            {renderCurrentStep}
          </div>

          <div className="grid h-[60px] w-full grid-cols-1 gap-x-5 bg-dark-700">
            {tabs.map((tab, key) => (
              <div className="flex items-center justify-center">
                <button
                  onClick={() =>
                    userWallet && setCurrentStep(tab.name as WidgetStep)
                  }
                  className={cn(
                    'text-[10px] h-[42px] w-[74px] rounded-md flex flex-col items-center justify-center gap-[1px] text-[#A1A7BB]',
                    currentStep === tab.name && 'text-white !bg-primary',
                  )}
                  key={key}>
                  <tab.icon active={currentStep === tab.name} />
                  {tab.name}
                </button>
              </div>
            ))}
          </div>
        </Modal>
      )}
    </>
  );
};

export default Widget;
